import { useContext, useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { Col, Divider, Form, Row, message } from 'antd';
import axios from 'axios';

import ToolTip from '../../../components/tooltip';
import Modal from '../../../components/modal';
import Input from '../../../components/input';
import Button from '../../../components/button';
import CustomerOutreachContext from '../../../context/customerOutreachContext';

import styles from './actions.module.scss';

const Actions = (p) => {
	const { getData, setContactInfoUpdate } = useContext(CustomerOutreachContext);
	const [modal, setModal] = useState(false);
	const [disable, setDisable] = useState(false);
	const [rowData, setRowData] = useState(p.data);
	const [form] = Form.useForm();

	const openModal = () => {
		setRowData(p.data);
		setModal(true);
	};

	const closeModal = () => {
		setRowData(null);
		setModal(false);
	};

	const onFinish = (values) => {
		setContactInfoUpdate(null);
		const formData = new FormData();
		formData.append('firstName', values?.firstName);
		formData.append('lastName', values?.lastName);
		formData.append('email', values?.email);
		formData.append('jobTitle', values?.jobTitle);
		formData.append('contactId', p.data?.ContactId);
		setDisable(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=update_crm_contacts`,
				formData
			)
			.then((response) => {
				setContactInfoUpdate({
					response: response.data,
					message: `Congrats! The Contact Info for ${rowData?.Account} has been changed in the CRM, however please wait till tomorrow for the changes to reflect here, as we are using a cached dataset. Verify the change in the CRM, if you want to view the change.`,
				});
				getData();
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
			})
			.finally(() => {
				setDisable(false);
				setModal(false);
				setRowData(null);
			});
	};

	const initialValues = {
		firstName: rowData?.ContactFirstName,
		lastName: rowData?.ContactLastName,
		email: rowData?.ContactEmail,
		jobTitle: rowData?.JobTitle,
	};

	return (
		<div className={styles['wrapper']}>
			{p.columnName === 'actions' ? (
				<ToolTip title="Edit Contact Info">
					<MdModeEdit className={styles['edit-icon']} onClick={openModal} />
				</ToolTip>
			) : (
				<span
					onDoubleClick={openModal}
					style={{ color: '#0070C1', cursor: 'pointer' }}
				>
					{p.value}
				</span>
			)}

			<Modal
				title={
					<>
						<p>Edit Contact Info for {rowData?.Account}</p>
						<Divider
							style={{
								margin: '5px 0',
								borderBlockStart: '1px solid rgb(216, 216, 216)',
							}}
						/>
					</>
				}
				open={modal}
				onCancel={closeModal}
				destroyOnClose
				width={700}
			>
				<Form
					name="edit-customer-outreach-info"
					form={form}
					onFinish={onFinish}
					validateTrigger={['onBlur', 'onChange']}
					labelCol={{ span: 24 }}
					requiredMark={false}
					initialValues={initialValues}
					disabled={disable}
				>
					<Row gutter={16}>
						<Col sm={24} md={12}>
							<Form.Item
								label="First Name"
								name="firstName"
								rules={[
									{
										required: true,
										message: 'First name can not be empty',
									},
									() => ({
										validator(_, value) {
											if (value?.length <= 2) {
												return Promise.reject(
													new Error(
														'First name can not contain fewer than 2 characters'
													)
												);
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input placeholder="Please enter First Name" />
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								label="Last Name"
								name="lastName"
								rules={[
									{
										required: true,
										message: 'Last name can not be empty',
									},
									() => ({
										validator(_, value) {
											if (value?.length <= 2) {
												return Promise.reject(
													new Error(
														'Last name can not contain fewer than 2 characters'
													)
												);
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input placeholder="Please enter Last Name" />
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								label="Email"
								name="email"
								rules={[
									{
										required: true,
										message: 'Please enter email!',
										whitespace: true,
									},
									{ type: 'email', message: 'Please enter valid email!' },
								]}
							>
								<Input placeholder="Please enter Email" />
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								label="Title"
								name="jobTitle"
								rules={[
									{
										required: true,
										message: 'Please enter Title!',
									},
								]}
							>
								<Input placeholder="Please enter Title" />
							</Form.Item>
						</Col>

						<Col sm={24}>
							<Form.Item style={{ marginBottom: '0px' }}>
								<div className={styles['actions']}>
									<Button type="bordered" onClick={closeModal}>
										Cancel
									</Button>
									<Button htmlType="submit">Process</Button>
								</div>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default Actions;
