import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PiMicrosoftOutlookLogoFill } from 'react-icons/pi';
import { AgGridReact } from 'ag-grid-react';
import { Checkbox, DatePicker, Progress } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { HiOutlineSearch } from 'react-icons/hi';
import dayjs from 'dayjs';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import InnerHeader from '../../components/innerHeader';
import Select from '../../components/select';
import Input from '../../components/input';
import Button from '../../components/button';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray } from '../../utils';
import MailPreview from '../../components/MailPreview';
import EdenContext from '../../context/edenContext';
import SearchImage from '../../images/Search Girl.svg';

import styles from './myPastEmails.module.scss';

const MyPastEmails = () => {
	const { userMail } = useContext(EdenContext);
	const gridRef = useRef();
	const { RangePicker } = DatePicker;
	const minDate = dayjs('2023-01-11');
	const maxDate = dayjs();

	// Default start and end dates
	const defaultStartDate = dayjs().subtract(6, 'month').isBefore(minDate)
		? minDate
		: dayjs().subtract(6, 'month');
	const defaultEndDate = maxDate;

	// State to manage date range
	const [dateRange, setDateRange] = useState([
		defaultStartDate,
		defaultEndDate,
	]);
	const [percent, setPercent] = useState(100);
	const [timeLeft, setTimeLeft] = useState(30);
	const [showMessage, setShowMessage] = useState(false);
	const [gridApi, setGridApi] = useState(null);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [dpValue, setDpValue] = useState('incoming');
	const [searchValue, setSearchValue] = useState('');
	const [checkbox, setCheckbox] = useState(['searchSubject']);

	const disabledDate = (current) => {
		return current && (current.isBefore(minDate) || current.isAfter(maxDate));
	};

	const onDateChange = (dates) => {
		if (dates) {
			setDateRange(dates);
		}
	};
	const [columnsDefs] = useState([
		{
			headerName: 'Mail',
			field: 'cid',
			cellStyle: { padding: '4px 0 0 25px' },
			cellClass: 'hyperlinks',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 80,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(MailPreview),
			cellRendererParams: {
				mailPreview: 'my-past-email',
			},
		},
		{
			field: 'receivedDateTime',
			headerName: 'Date/TimeDate/Time​',
			cellRenderer: (p) => (
				<> {p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}</>
			),
		},
		{
			field: 'senderAddress',
			headerName: 'From​',
		},
		{
			field: 'toRecipients',
			headerName: 'To',
		},
		{
			headerName: 'Subject',
			field: 'subject',
		},
		{
			headerName: 'Attachment? Y/N',
			field: 'hasAttachments',
			cellRenderer: (p) => <> {p.value === 0 ? 'No' : 'Yes'}</>,
		},
	]);

	const onChange = (value) => {
		setDpValue(value);
	};

	const handleSearch = () => {
		setIsLoading(true);
		setPercent(100);
		setTimeLeft(30);
		setShowMessage(false);
		setData([]);
		setError(null);

		const timer = setInterval(() => {
			setTimeLeft((prev) => {
				if (prev <= 1) {
					clearInterval(timer);
					setPercent(0);
					setShowMessage(true);
					return 0; // Ensure no negative timeLeft
				}
				return prev - 1;
			});
			setPercent((prev) => Math.max(0, Math.round(prev - 100 / 30))); // Avoid negative percent
		}, 1000);

		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=mailvault_search`,
				{
					params: {
						keyword: searchValue,
						searchSubject: checkbox.includes('searchSubject'),
						searchBody: checkbox.includes('searchBody'),
						searchSenderEmail: checkbox.includes('searchSenderEmail'),
						direction: dpValue,
						limit: 0,
						offset: 0,
						user: userMail,
						token: localStorage.getItem('accessToken'),
						startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
						endDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
					},
				}
			)
			.then((response) => {
				setData(response.data);
				setError(null);
				setShowMessage(false);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	//Export to excel
	const exportToExcel = () => {
		gridApi.exportDataAsExcel();
	};

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
		{
			id: 'hyperlinks',
			font: {
				underline: 'Single',
				color: '#358ccb',
			},
		},
	];

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	const checkOptions = [
		{
			label: 'Subject',
			value: 'searchSubject',
		},
		{
			label: 'Body',
			value: 'searchBody',
		},
		{
			label: 'Sender Email',
			value: 'searchSenderEmail',
		},
	];

	const onCheckboxChange = (checkedValues) => {
		let updatedValues;

		if (checkedValues.includes('searchSenderEmail')) {
			updatedValues = ['searchSenderEmail'];
		} else {
			updatedValues = checkedValues;
		}

		setCheckbox(updatedValues);
	};

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="My Past Emails"
				icon={<PiMicrosoftOutlookLogoFill style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="My Past Emails"
				info={
					<div>
						This section allows you to look for historical emails that are
						probably not saved in your mailbox. <br />
						<br />
						Please take note of the following important information:
						<br />
						<ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
							<li>
								Our system has only saved emails since <b>January 11th 2023</b>.
							</li>
							<li>
								If you are looking for information prior to that, you may need
								to look at your own Archival emails.
							</li>
							<li>
								The search box below allows you to search for keywords by either
								Subject Line or Body text.
							</li>
							<li>
								Because of the volume of emails, it might take some time for the
								results to appear. Please be patient!
							</li>
							<li>
								If the system determines that the results will take more than 30
								seconds, an email shall be sent to you.
							</li>
						</ul>
					</div>
				}
			/>
			<InnerHeader exportToExcel={exportToExcel} />
			<div className={styles['my-past-emails']}>
				<Checkbox.Group
					disabled={isLoading}
					options={checkOptions}
					value={checkbox}
					onChange={onCheckboxChange}
				/>
				<div className={styles['input-checkbox']}>
					<div className={styles['dropdown-input']}>
						<Select
							placeholder="Drill down by"
							style={{
								width: 100,
							}}
							options={[
								{ value: 'incoming', label: 'Incoming' },
								{ value: 'outgoing', label: 'Outgoing' },
							]}
							defaultValue={dpValue}
							onChange={onChange}
							disabled={isLoading}
						/>
						<Input
							style={{ width: '450px' }}
							placeholder={
								'Search keywords in to/from emails, subject lines or body text…'
							}
							onChange={(e) => setSearchValue(e.target.value)}
							onPressEnter={handleSearch}
							disabled={isLoading}
							id="searchValue"
							value={searchValue}
						/>
					</div>
					<RangePicker
						disabledDate={disabledDate}
						format="YYYY-MM-DD"
						value={dateRange}
						onChange={onDateChange}
						allowClear={false}
						disabled={isLoading}
					/>

					<Button
						onClick={handleSearch}
						disabled={
							searchValue?.length <= 1 ||
							isLoading ||
							checkbox.length <= 0 ||
							!dateRange[0] ||
							!dateRange[1]
						}
					>
						Search <HiOutlineSearch />
					</Button>
				</div>

				{isLoading && !showMessage && (
					<div className={styles['loading-wrapper']}>
						<Progress
							type="circle"
							percent={percent}
							strokeColor="#001433"
							status="active"
						/>
						<p className={styles['time-left']}>{timeLeft} seconds remaining</p>
					</div>
				)}

				{!isNotEmptyArray(data) && !error && showMessage && (
					<div className={styles['delay-wrapper']}>
						<h3>We apologize for the delay!</h3>
						<p>
							Given the volume of emails in our database, the system needs more
							time to parse and identify all your relevant emails.
						</p>
						<p>
							Once the system has completed the search, you will receive an
							email. This can take up to 30 minutes, so please check your email
							periodically.
						</p>
						<p>
							Should you not have any results, please send an issue ticket via
							the HD section in Eden.
						</p>
					</div>
				)}

				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !isLoading && !error && (
					<div className={styles['no-data-wrapper']}>
						<img className={styles['search-image']} src={SearchImage} alt="" />
						<p className={styles['no-data']}>
							Since the system shall search for all relevant emails based your
							search criteria since the beginning of time. Given the number of
							emails for the entire company in the database, it can take a long
							time to extract the data that you need. <br /> <br /> Upon
							searching, if the system isn’t able to capture your results, the
							system will <b>email you</b> with the results upon searching. This
							can take up to 30 minutes, so please be on the lookout in your
							email. <br /> <br />
							Should you not have any results, please send it an issue ticket
							via the HD section in Eden.{' '}
						</p>
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
						<AgGridReact
							ref={gridRef}
							onGridReady={onGridReady}
							rowData={data}
							columnDefs={columnsDefs}
							defaultColDef={defaultColDef}
							// rowModelType="serverSide"
							animateRows={true}
							floatingFilter={true}
							excelStyles={excelStyles}
							pagination={true}
							paginationPageSizeSelector={false}
							// paginationPageSize={50}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default MyPastEmails;
