import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { FaBook } from 'react-icons/fa';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';

import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import AddFranchisedBrandForm from './From';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import Actions from './Actions';
import FranchisedBrandsContext from '../../../context/franchisedBrands';
import Button from '../../../components/button';
import { COUNTRY_CODES } from '../../../columns';

import styles from './franchisedBrands.module.scss';

const CountryCellRenderer = (props) => {
	const [value, setValue] = useState('');
	let data;

	try {
		data =
			props.value && props.value.startsWith('{')
				? JSON.parse(props.value)
				: null;
	} catch (error) {
		console.error('Invalid JSON in props.value:', error);
		data = null;
	}

	useEffect(() => {
		if (!props.value) {
			setValue(props.isFilterRenderer ? '(Blanks)' : props.value);
		} else if (props.value === '(Select All)') {
			setValue(props.value);
		} else if (data?.unicodeFlag) {
			// Use the unicodeFlag from the parsed data
			const url = `https://flags.fmcdn.net/data/flags/mini/${
				COUNTRY_CODES[data?.name] || 'default'
			}.png`;
			setValue(url);
		} //eslint-disable-next-line
	}, []);

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
			{value &&
				(value === '(Select All)' ? null : (
					<img
						style={{ width: '15px' }}
						src={value || 'https://via.placeholder.com/15'}
						alt={data?.name || 'No name'}
					/>
				))}
			<span>{data?.name || props.value}</span>
		</div>
	);
};

const FranchisedBrands = () => {
	const { getData, data, isLoading, error } = useContext(
		FranchisedBrandsContext
	);

	const [drawer, setDrawer] = useState(false);

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const [columnDefs] = useState([
		{
			field: 'actions',
			headerName: 'Actions',
			maxWidth: 90,
			cellStyle: { padding: '4px 0 0 10px' },
			cellRenderer: memo(Actions),
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			field: 'companyName',
			headerName: 'Company Name',
			cellRenderer: (p) => (
				<Link
					to={p.data.websiteUrl}
					target="_blank"
					style={{ color: 'blue', textDecoration: 'none' }}
				>
					{p.value}
				</Link>
			),
		},
		{
			field: 'headquarters',
			headerName: 'Headquarters',
			cellRenderer: memo(CountryCellRenderer),
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: memo(CountryCellRenderer),
				cellRendererParams: { isFilterRenderer: true },
				applyMiniFilterWhileTyping: true,
			},
		},
		{
			field: 'displayonUI',
			headerName: 'Enable/Disable',
			cellRenderer: (p) => (p.value === true ? 'Yes' : 'No'),
		},
		{
			field: 'displayInRegions',
			headerName: 'Exclude Option',
		},
		// {
		// 	field: 'shortDescription',
		// 	headerName: 'Short Description',
		// 	cellRenderer: (params) => {
		// 		const div = document.createElement('div');
		// 		div.innerHTML = params.value || '';
		// 		return div.textContent || div.innerText || '';
		// 	},
		// },
		// {
		// 	field: 'longDescription',
		// 	headerName: 'Long Description',
		// 	cellRenderer: (params) => {
		// 		const div = document.createElement('div');
		// 		div.innerHTML = params.value || '';
		// 		return div.textContent || div.innerText || '';
		// 	},
		// },
		{
			field: 'automotive',
			headerName: 'Automotive?',
			hide: true,
		},
		{
			field: 'categories',
			headerName: 'Product Categories',
			hide: true,
		},
		{
			field: 'productCategories',
			headerName: 'Product Type',
			hide: true,
		},
		{
			field: 'authorizationLetter',
			headerName: 'Authorization Letter',
			hide: true,
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const openDrawer = () => {
		setDrawer(true);
	};

	const initialValues = {
		displayonUI: true,
		displayInRegions: 'Show in All Regions',
		automotive: false,
	};

	const defaultExcelExportParams = useMemo(() => {
		return {
			sheetName: 'Franchised Line',
			fileName: 'Franchised Line.xlsx',
			autoConvertFormulas: true,
			columnKeys: [
				'companyName',
				'headquarters',
				'automotive',
				'categories',
				'productCategories',
				'displayonUI',
				'displayInRegions',
				'authorizationLetter',
			],
			processCellCallback: (params) => {
				const { column, value } = params;

				switch (column.colId) {
					case 'companyName':
						return value || '';

					case 'headquarters':
						return value ? JSON.parse(value).name : '';

					case 'categories':
					case 'productCategories':
						return value ? JSON.parse(value).join(', ') : '';

					case 'automotive':
					case 'displayonUI':
						return value ? 'Yes' : 'No';
					case 'authorizationLetter':
						return value ? 'Yes' : 'No';

					default:
						return value;
				}
			},
		};
	}, []);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Franchised Brands"
				icon={<FaBook style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Franchised Brands"
				info="Franchised Brands"
			/>

			<div className={styles['franchised-brands']}>
				<Button onClick={openDrawer}>Add Franchised Line</Button>
				{drawer && (
					<AddFranchisedBrandForm
						drawer={drawer}
						setDrawer={setDrawer}
						initialValues={initialValues}
					/>
				)}
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}
				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Select add franchised brand." />
					</div>
				)}
				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']}`}>
							<AgGridReact
								rowData={data}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								animateRows={true}
								pagination={true}
								paginationPageSizeSelector={false}
								paginationPageSize={30}
								defaultExcelExportParams={defaultExcelExportParams}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FranchisedBrands;
